import { render, staticRenderFns } from "./detalle.vue?vue&type=template&id=077d0ff3&scoped=true&"
import script from "./detalle.vue?vue&type=script&lang=js&"
export * from "./detalle.vue?vue&type=script&lang=js&"
import style0 from "./detalle.vue?vue&type=style&index=0&id=077d0ff3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077d0ff3",
  null
  
)

export default component.exports