import Vue from 'vue'
import Vuex from 'vuex'
import {axios2 as axios} from "@/interceptor/useApi";
import Swal from 'sweetalert2';

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    Ejes: [],
    Items: [],
    Plantas: [],
    Loader: true,
    ItemTotal: 0,
    Vehiculos: [],
    TiposValvula: [],
    Inspecciones: [],
    MotivosInaccesibilidad: [],
    ArrayIdInspecciones: []
  },
  mutations: {
    LlenarItems(state, data){
      state.Items = data
    },
    LlenarVehiculos(state, data) {
      state.Vehiculos = data
    },
    LlenarPlantas(state, data) {
      state.Plantas = data
    },
    LlenarEjes(state, data) {
      state.Ejes = data
    },
    LlenarMotivosInaccesibilidad(state, data){
      state.MotivosInaccesibilidad = data
    },
    LlenarTiposValvula(state, data){
      state.TiposValvula = data
    },
    LlenarItemTotal(state, data) {
      state.ItemTotal = data
    },
    StateLoader(state, data) {
      state.Loader = data
    }
  },
  actions: {
    messageErrorAlert: function () {
      Swal.fire({
        title: "Upps, algo paso!",
        text: "Servidor desconectado, por favor actualice la ventana!",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#d33",
        confirmButtonText: "Actualizar",
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
        backdrop: `
          rgba(255,0,0,0.1)
          left top
          no-repeat
        `,
      }).then((result) => {
        if (result.isConfirmed) {
          location.reload();
        }
      });
    },

    cargarDatosAdicionales: async function ({ commit, dispatch }) {
      try {
        const url = "inspecciones/list";
        const data = {
          id_cliente: localStorage.getItem("idcliente")
        };
        await axios
          .post(url, data)
          .then((response) => {
            let listVehiculos = [];
            response.data.success.vehiculos.forEach((element, i) => {
              listVehiculos[i] = {
                value: element.id,
                text: element.placa + ' / ' + element.codigo,
                tipovehiculo: element.nomtipo,
                id_tipo: element.id_tipo,
                id_planta: element.id_planta,
                id_configuracion: element.id_configuracion
              };
            });

            let listPlantas = [];
            response.data.success.plantas.forEach((element, i) => {
              listPlantas[i] = {
                value: element.id,
                text: element.nombre
              };
            });

            let listEjes = [];
            response.data.success.ejes.forEach((element, i) => {
              listEjes[i] = {
                value: element.id,
                text: element.descripcion
              };
            });

            let listMotivos = [];
            response.data.success.motivosinaccesibilidad.forEach((element, i) => {
              listMotivos[i] = {
                value: element.numero,
                text: element.dato
              };
            });

            let listTiposValvula = [];
            response.data.success.tiposvalvula.forEach((element, i) => {
              listTiposValvula[i] = {
                value: element.codigo,
                text: element.dato
              };
            });

            commit("LlenarVehiculos", listVehiculos);
            commit("LlenarPlantas", listPlantas);
            commit("LlenarEjes", listEjes);
            commit("LlenarMotivosInaccesibilidad", listMotivos);
            commit("LlenarTiposValvula", listTiposValvula);           
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarInspecciones: async function ({ commit, dispatch }) {
      try {
        const url = "inspecciones/getAllInspectionsMinified";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };
        commit("StateLoader", true);
        await axios
          .post(url, data)
          .then((response) => {
            response.data.success.resultado.forEach(element => {
              element.loader = false;
            });
            commit("LlenarItems", response.data.success.resultado);
            commit("LlenarItemTotal", response.data.success.resultado.length);
            commit("StateLoader", false);
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    cargarInspeccionesPorIdentificador: async function({ commit, dispatch }, identificador){
      try {
        let array_id_inspecciones = [];
        const url = "inspecciones/getAllInspectionsMinifiedPerIdentifier/" + identificador;
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
        };
        await axios
          .post(url, data)
          .then((response) => {
            response.data.success.resultado.forEach(element => {
              array_id_inspecciones.push(element.id);
            });
          })
          .catch((error) => {
            this.errorMessage = error.response.data;
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
          return array_id_inspecciones;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    validarCodigo: async function( {commit, dispatch}, codigo){
      try {
        const url = "inspecciones/validateCodigoInspeccion";
        const data = {
          id_cliente: localStorage.getItem("idcliente"),
          codigo: codigo
        };
        let existe = false;
        await axios
          .post(url, data)
          .then((response) => {
            existe = response.data.success.respuesta;
          })
          .catch((error) => {
            console.error("error!", error);
            dispatch("messageErrorAlert");
          });
          return existe;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      } 
    },

    validarFecha: async function ({ commit, dispatch }, item) {
      try {
        const url = "vehiculos/validateFechaInspeccion";
        const data = {
          clienteId: localStorage.getItem("idcliente"),
          id_cliente: localStorage.getItem("idcliente"),
          id_vehiculo: item.id_vehiculo,
          fecha_inspeccion: item.fecha_inspeccion
        };
        let mensaje = null;
        await axios
          .post(url, data)
          .then((response) => {
            mensaje = response.data.message;
          })
          .catch((error) => {
            mensaje = error.response.data.message;
            console.error("error!", error);
            // dispatch("messageErrorAlert");
          });
          return mensaje;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    validarKm: async function ({ commit, dispatch }, item) {
      try {
        const url = "vehiculos/validateKilometraje";
        const data = {
          clienteId: localStorage.getItem("idcliente"),
          id_cliente: localStorage.getItem("idcliente"),
          id_vehiculo: item.id_vehiculo,
          km: item.km_inspeccion
        };
        let mensaje = null;
        await axios
          .post(url, data)
          .then((response) => {
            mensaje = response.data.message;
          })
          .catch((error) => {
            mensaje = error.response.data.message;
            console.error("error!", error);
            // dispatch("messageErrorAlert");
          });
          return mensaje;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },

    validarRemanente: async function ({ commit, dispatch }, item,descripcion) {
      try {
        console.log(item.descripcion_remanente_validado,'descripcion');
        const url = "neumaticos/validateRemanentes";
        const data = {
          clienteId: localStorage.getItem("idcliente"),
          id_cliente: localStorage.getItem("idcliente"),
          id_neumaticos: item.id_neumaticos,
          descripcion: item.descripcion_remanente_validado,
          exterior: item.exterior,
          interior: item.interior
        };
        let mensaje = null;
        await axios
          .post(url, data)
          .then((response) => {
            mensaje = response.data;
          })
          .catch((error) => {
            mensaje = error.response.data.message;
            console.error("error!", error);
            // dispatch("messageErrorAlert");
          });
          return mensaje;
      } catch (error) {
        console.log(error);
        dispatch("messageErrorAlert");
      }
    },
    validarDuplicidad: async function ({ commit, dispatch }, item) {
      try {
        const url = "inspecciones/validate/duplicate";
        const data = {
          clienteId: localStorage.getItem("idcliente"),
          id_cliente: localStorage.getItem("idcliente"),
          id_vehiculo: item.id_vehiculo,
          km_inspeccion: item.km_inspeccion,
          fecha_inspeccion: item.fecha_inspeccion
        };
        let mensaje = null;
        await axios
          .post(url, data)
          .then((response) => {
            if (response.data.status==false) {
              mensaje='';
            } else {
              mensaje = response.data.message;
            }
          })
          .catch((error) => {
            mensaje = "";
            console.error("error!", error);
            // dispatch("messageErrorAlert");
          });
          return mensaje;
      } catch (error) {
        console.log(error);
        
        mensaje = "";
        // dispatch("messageErrorAlert");
      }
    },
  }
}


